import React, { Component } from 'react'
import { Container, Hidden, Row } from 'react-grid-system'
import { translate } from 'react-i18nify'
import { editProfileItem, getJobs } from '../../store/actions/user'

import GridCol from '../GridCol/GridCol'
import GridColOffset from '../GridCol/GridColOffset'
import Intro from './Steps/Intro'
import HighestStudy from './Steps/HighestStudy'
import Documents from './Steps/Documents'
import Discovery from './Steps/Discovery'
import Outro from './Steps/Outro'
import PersonalInfo from './Steps/PersonalInfo'
import PersonalInformationLateral from './Steps/LateralSteps/PersonalInformationLateral'
import FlexAppStep from './Steps/FlexAppStep'
import CreationOptions from './Steps/CreationOptions'

import { connect } from 'react-redux'
import { ACCOUNT_ROLES, PATH_PROFILE, LATERAL_CREATION_OPTIONS } from '../../constants/Constants'
import history from '../../history'

class Onboarding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            onboardingStep: 0,
            states: {},
            jobs: [],
        }
        this.nextStep = this.nextStep.bind(this)
        this.prevStep = this.prevStep.bind(this)
        this.finishOnboarding = this.finishOnboarding.bind(this)
        this.saveCache = this.saveCache.bind(this)
    }

    // todo: refactor how onboarding works, mount all steps and just show the one you need and move the
    // api dependencies to the components
    componentDidMount() {
        this.getJobs()
    }

    getJobs() {
        getJobs(this.props.auth, false, (response) => {
            this.setState({
                jobs: response,
            })
        })
    }

    nextStep() {
        if (this.state.onboardingStep + 1 === this.getOnboardingSteps().length) {
            this.finishOnboarding()
            return
        }

        this.setState({ onboardingStep: this.state.onboardingStep + 1 })
    }

    prevStep() {
        this.setState({ onboardingStep: this.state.onboardingStep - 1 })
    }

    saveCache(key, cache) {
        this.setState(({ states }) => ({
            states: { ...states, [key]: cache },
        }))
    }

    finishOnboarding() {
        const { accTypes } = this.props

        this.props.dispatch(
            editProfileItem(
                'general',
                { onboarding_done: true },
                this.props.auth,
                () => {
                    if (!accTypes.includes(ACCOUNT_ROLES.PLANNER)) {
                        setTimeout(history.push(PATH_PROFILE + '?onboarding=true&number=1'), 2000)
                    }
                },
                () => {},
            ),
        )
    }

    isFlexEmployee() {
        const { user } = this.props
        return user.profile.account_types.find((type) => type.code === 'flexapp-employee')
    }

    shouldSeeFlexStep() {
        const {
            config: {
                whitelabel: { flexapp },
            },
        } = this.props
        return flexapp && flexapp.used && this.isFlexEmployee()
    }

    shouldSeeLateralSpecificSteps() {
        const { user, config } = this.props
        const { onboarding_lateral_entrants } = config.whitelabel

        return (
            user?.profile?.creation_option &&
            onboarding_lateral_entrants &&
            LATERAL_CREATION_OPTIONS.includes(user.profile.creation_option)
        )
    }

    // this.props.config.whitelabel.my_flag ? {}
    getOnboardingSteps() {
        const { accTypes } = this.props
        const { flexapp, onboarding_lateral_entrants } = this.props.config.whitelabel
        const flexUrl = flexapp ? flexapp.url : null
        const stepProps = {
            nextStep: this.nextStep,
            prevStep: this.prevStep,
            auth: this.props.auth,
            user: this.props.user,
            cache: this.state.states,
            saveCache: this.saveCache,
            onboardingStep: this.state.onboardingStep,
        }

        let steps = [
            {
                key: 'intro',
                title: translate('onboarding.title1'),
                el: <Intro {...stepProps} />,
                skipFor: [ACCOUNT_ROLES.PLANNER],
                skipWhen: false,
            },
        ]

        // Check if feature flag is enabled
        if (onboarding_lateral_entrants) {
            steps = steps.concat([
                {
                    key: 'creationOptions',
                    title: translate('creation_options.onboarding_title'), //translate('onboarding.title2'),
                    el: <CreationOptions {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                    skipWhen: !onboarding_lateral_entrants,
                },
            ])
        }

        if (this.shouldSeeLateralSpecificSteps()) {
            //Specific steps for lateral entrants
            const lateralSpecificSteps = [
                {
                    key: 'personalInformationLateral',
                    title: translate('onboarding.title2'),
                    el: <PersonalInformationLateral {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'highest_study',
                    title: translate('onboarding.title2'),
                    el: <HighestStudy {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'documents',
                    title: translate('onboarding.title2'),
                    el: <Documents {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'discovery',
                    title: translate('onboarding.title2'),
                    el: <Discovery {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'outro',
                    title:
                        !accTypes.includes(ACCOUNT_ROLES.PLANNER) && translate('onboarding.title1'),
                    el: (
                        <Outro
                            {...stepProps}
                            flexUrl={flexUrl}
                            isFlexEmployee={this.isFlexEmployee()}
                            isPlanner={accTypes.includes(ACCOUNT_ROLES.PLANNER)}
                            isLateral={true}
                        />
                    ),
                    skipFor: [],
                },
            ]
            steps = steps.concat(lateralSpecificSteps)
        } else {
            //Default steps
            const defaultSteps = [
                {
                    key: 'personalInfo',
                    title: translate('onboarding.title2'),
                    el: <PersonalInfo {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'highest_study',
                    title: translate('onboarding.title2'),
                    el: <HighestStudy {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },

                this.shouldSeeFlexStep() && {
                    key: 'flex_app_step',
                    title: translate('onboarding.title2'),
                    el: <FlexAppStep {...stepProps} />,
                    skipFor: [ACCOUNT_ROLES.PLANNER],
                },
                {
                    key: 'outro',
                    title:
                        !accTypes.includes(ACCOUNT_ROLES.PLANNER) && translate('onboarding.title5'),
                    el: (
                        <Outro
                            {...stepProps}
                            flexUrl={flexUrl}
                            isFlexEmployee={this.isFlexEmployee()}
                            isPlanner={accTypes.includes(ACCOUNT_ROLES.PLANNER)}
                        />
                    ),
                    skipFor: [],
                },
            ]
            steps = steps.concat(defaultSteps)
        }

        return steps.filter(Boolean).filter((step) => {
            const shouldSkipForRoles = step.skipFor.some((skipRole) => accTypes.includes(skipRole))
            return !shouldSkipForRoles && !step.skipWhen
        })
    }

    render() {
        return (
            <div className={'onboarding__backdrop'}>
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <div className="onboarding">
                                <Hidden xs>
                                    <div className="card--simple__intro">
                                        <span>
                                            {
                                                this.getOnboardingSteps()[this.state.onboardingStep]
                                                    .title
                                            }
                                        </span>
                                    </div>
                                </Hidden>

                                {this.getOnboardingSteps()[this.state.onboardingStep].el}

                                <Hidden xs>
                                    <ul className="bullet_list">
                                        {this.getOnboardingSteps().map((step, idx) => {
                                            return (
                                                <li key={step.key}>
                                                    <span
                                                        className={(
                                                            this.state.onboardingStep === idx &&
                                                            'is-active'
                                                        ).toString()}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Hidden>
                            </div>
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
        accTypes: user.profile.account_types.map((type) => type.code),
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Onboarding)
